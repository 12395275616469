import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 用来存储数据
const state = {
  infoData: {},
  footerList: [],
  userInfo: {}
}
// 响应组件中的事件
const actions = {
  infoData(content, value) {
    content.commit("getInfoData", value)
  },
  footerList(content, value) {
    content.commit("getFooterList", value)
  },
  userInfo(content, value) {
    content.commit("getUserInfo", value)
  }
}
// 操作数据
const mutations = {
  getInfoData(content, value) {
    this.state.infoData = value
  },
  getFooterList(content, value) {
    this.state.footerList = value
  },
  getUserInfo(content, value) {
    this.state.userInfo = value
  }
}
// 用来将state数据进行加工
const getters = {

}
// 新建并暴露store
export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters
})
